import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import {shortTokenLink, solBeautify, solNumber} from '../../components/Helper';
import MarketID from './MarketID';
import Log from './Log';

const BuySell : React.FC = () => {
    const [param, setParam] = useState<any>(null);
    const [logParam, setLogParam] = useState<any>(null);
    const [logTitle, setLogTitle] = useState<any>(null);
    const [timeTaken, setTimeTaken] = useState(0);
    
    const [sellPercent, setSellPercent] = useState(0);
    const [solAmount, setSolAmount] = useState(0);
    const [PoolInfo, setPoolInfo] = useState<any>(null);
    const [marketId, setMarketId] = useState('');


    const [walletList, setWalletList] = useState([]);

    useEffect(() => {
        const config_names = { "wallets" : ["SWAP_wallet"] };
        get_config(config_names);
    }, []);

    function get_config(params){
        fetch('https://private.neutron.ag/config/',
                {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(params)
                }
              )
              .then(response => {
                  return response.json(); 
              })
              .then(data => {
                setWalletList(data);
              })
              .catch(error => {
                  console.log('Fetch Error:', error);
              });
    }

    const [balanceSWAP, setBalanceSWAP] = useState<any>(null);

    useEffect(() => {
        if(walletList != null) {
            fetch_balance(walletList?walletList["SWAP_wallet"]:null);
        }
    },[walletList]);

    function fetch_balance(publicKey){
        if(publicKey!= null){
            fetch('https://private.neutron.ag/balance/' + publicKey)
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setBalanceSWAP(data);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    }
    

    const handleSolAmountChange = (e) => {
        setSolAmount(e.target.value);
    };


    useEffect(() => {
        fetch('https://private.neutron.ag/params')
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                setParam(data);
                setMarketId(data?.Market_ID);
                setSolAmount(data?.Buy_Amount);
                setSellPercent(data?.Sell_Percent);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    },[]);



    const handleBuy = () => {
        setLogParam(null);
        setTimeTaken(0);

        let params = {pool_info : PoolInfo, sol_amount : solAmount}
        fetch('https://private2.neutron.ag/swap_buy',
        {
            method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(params)
        })
        .then(response => {
            return response.json(); 
        })
        .then(data => {
                setLogTitle("Swap - Buy");
                setLogParam(data);
                // setTimeTaken(data.time);
        })
        .catch(error => {
            console.log('Fetch Error:', error);
        });
    }

    const handleBuynSell = () => {

    }
    
    return (
        <><Header/>
          <div className="mx-auto">
            <div className="w-ful md:flex mt-4 gap-4">
                <div className="w-1/6 max-sm:w-full max-sm:flex justify-center">
                    <ToolNavigation/>
                </div>

                <div className="w-5/6 max-sm:w-full max-sm:mt-8 ">
                    <div className='w-full md:flex gap-4'>
                        <div className='w-2/3 max-sm:w-full'>
                            <MarketID marketID={marketId}/>

                            <div className='w-full  mt-4 x-board p-4'>
                                <table className='table-auto w-full'>
                                        <tr className="align-top">
                                            <td>SWAP Wallet</td>
                                            <td>
                                                <div>{walletList?walletList["SWAP_wallet"]:null} <a href='/tool' className='underline text-orange-500 float-right'>Change</a></div>
                                                <div>Max : {solNumber(balanceSWAP?.balance)} SOL</div>
                                            </td>
                                        </tr>

                                        <tr className="align-top">
                                            <td>Buy (SOL)</td>
                                            <td>
                                                <input className="w-full text-black px-2 py-1" type="text" value={solAmount} onChange={handleSolAmountChange} />                                 
                                            </td>
                                        </tr>

                                        <tr className="align-top">
                                            <td>Sell %</td>
                                            <td><input className="w-full text-black px-2 py-1" type="text" value={sellPercent} />                                
                                            </td>
                                        </tr>

                                        <tr className="align-top">
                                        <td></td>
                                        <td>
                                            <div className='mt-4 flex'>
                                            

                                                <div className="w-1/3">
                                                    <button className="border px-2 text-orange-500" onClick={handleBuy}>1. Buy</button>
                                                   
                                                </div>

                                                <div className="w-1/3">
                                                    
                                                    <button className="border px-2 text-orange-500" onClick={handleBuynSell}>2. Buy + Sell</button>
                                                    
                                                </div>


                                                <div className="w-1/3">
                                                    <button className="border px-2 text-orange-500" onClick={handleBuynSell}>3.  Sell</button>
                                                    <div>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                        </td>
                                    </tr>
                                </table>
                            </div>                        
                        </div>
                    
                        <div className='w-1/3 max-sm:w-full max-sm:mt-4'>                          
                            <div className='w-full'>
                                    <Log logParam ={logParam} logTitle = {logTitle} timeTaken = {timeTaken}/>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
            
          </div>
      
      </>
    )

}

export default BuySell