import React, { useState, useEffect } from 'react';
import {solNumber, formatNumber} from '../../components/Helper';

const WalletBoard = () => {

    const [balanceLP, setBalanceLP] = useState(0);
    const [balanceFEE, setBalanceFEE] = useState(0);

    const [walletList, setWalletList] = useState([]);

    useEffect(() => {
        const config_names = { "wallets" : ["LP_wallet", "FEE_wallet"] };
        get_config(config_names);
    }, []);

    function get_config(params){
        fetch('https://private.neutron.ag/config/',
                {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(params)
                }
              )
              .then(response => {
                  return response.json(); 
              })
              .then(data => {
                setWalletList(data);
              })
              .catch(error => {
                  console.log('Fetch Error:', error);
              });
    }



    function fetch_balance(pubKey, type){
        if(pubKey != null){
            fetch('https://private.neutron.ag/balance/' + pubKey)
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    if(type === "LP"){
                        setBalanceLP(data.balance);
                    }
                    else if(type === "FEE"){
                        setBalanceFEE(data.balance);
                    }
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    }



    useEffect(() => {
        if(walletList != null) {
            fetch_balance(walletList?walletList["LP_wallet"]:null, "LP");
            fetch_balance(walletList?walletList["FEE_wallet"]:null, "FEE");
        }
    },[walletList]);


    return (
        <table className="w-full text-md">
            <tbody>
                <tr className="align-top">
                    <td><b>LP Wallet</b></td>
                    <td>
                        <div>{walletList?walletList["LP_wallet"]:null} <a href='/tool' className='underline text-orange-500 float-right'>Change</a></div>
                        <div>Max : <span className='text-yellow-300'>{solNumber(balanceLP)} SOL</span></div>
                    </td>
                </tr>

                <tr className="align-top">
                    <td><b>FEE Wallet</b></td>
                    <td>
                        <div>{walletList?walletList["FEE_wallet"]:null} <a href='/tool' className='underline text-orange-500 float-right'>Change</a></div>
                        <div>Max : <span className='text-yellow-300'>{solNumber(balanceFEE)} SOL</span></div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default WalletBoard;