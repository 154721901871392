import React from 'react';
import Navigation from './Navigation';
import WalletHeader from '../wallet/WalletHeader';

 const Header = () => {

    return (

        <div className="mx-auto bg-primary border-head-bottom  md:flex p-2 pl-4 pr-4">
                <div className="w-full  flex pt-1 pb-1 justify-center items-center gap-4">
                    <Navigation/>
                    <div className="absolute mx-auto">
                        <div className="clock w-40 max-sm:hidden" id="utc-clock">00:00:00 UTC</div>
                    </div>
                    <WalletHeader/>
                </div>
        </div>
      

    );
}

export default Header