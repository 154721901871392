import React, { useState, useEffect } from 'react';
import SendWalletArea from './SendWalletArea';
import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import {WalletContextProvider} from '../../components/wallet/WalletContextProvider';

function  SendWallet(){
    return (
      <><Header/>
        <div className="mx-auto">
          <div className="w-full md:flex mt-4 gap-4">
              <div className="w-1/6 max-sm:w-full max-sm:flex">
                  <ToolNavigation/>
              </div>
              <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                  <WalletContextProvider>
                      <SendWalletArea/>
                  </WalletContextProvider>
                  
              </div>
              
          </div>
        </div>
      </>
    )

  }
  
export default SendWallet