import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import MarketID from './MarketID';

const Market : React.FC = () => {

    const [marketId, setMarketId] = useState('');
    
    useEffect(() => {
        fetch('https://private.neutron.ag/params')
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                setMarketId(data?.Market_ID);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    },[]);


    return (
      <>
        <Header/>
        <div className="mx-auto">
            <div className="w-ful md:flex mt-4 gap-4">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                        <MarketID marketID={marketId} showLog={true}/>
                   
                </div>
            </div>
        </div>
      
      </>
    )
  }
  
export default Market