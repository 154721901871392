import React, { useState, useEffect } from 'react';
import SendTokenArea from './SendTokenArea';
import Header from '../../components/header/Header';
// import WalletHeader from '../../components/wallet/WalletHeader';
import ToolNavigation from './ToolNavigation'; 

function SendToken() {
    return (
      <><Header/>
        <div className="mx-auto mt-4">
          <div className="w-full mt-4 md:flex">
              <div className="w-1/6 max-sm:w-full max-sm:flex">
                  <ToolNavigation/>
              </div>
              <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                  <SendTokenArea/>
              </div>
          </div>
        </div>
      </>
    )

  }
  
export default SendToken