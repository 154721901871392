import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import {solNumber, shortTokenLink} from '../components/Helper';
import CryptoJS from 'crypto-js';
import TokenAmount from './TokenAmount';
import { useLocation } from 'react-router-dom';


const Tool : React.FC = () => {

    /*
    const secretKey = '1234567890abcdef';
    const iv = 'abcdef1234567890';

    function decrypt(encrypted : any){
      if(encrypted === null || encrypted === "") return;

      return encrypted;

       const decrypted = CryptoJS.AES.decrypt(encrypted, secretKey, { iv: iv }).toString(CryptoJS.enc.Utf8);
       return decrypted;
    }
    */
   
    let location = useLocation();

    useEffect(() => {
      // Kiểm tra xem URL có tham số 's' không
      const searchParams = new URLSearchParams(location.search);
      const _token = searchParams.get('token');
      if (_token) {
          setToken(_token);
      }
  }, [location]);


    const [walletList, setWalletList] = useState([]);
    const [tokenList, setTokenList] = useState([]);
    const [token, setToken] = useState('');

    useEffect(() => {
        fetch('https://private.neutron.ag/wallets')
            .then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
            })
            .then(data => {
              
              setWalletList(data);
            })
            .catch(error => {
              console.log('Fetch Error:', error);
            });
    },[]);

    const [items, setItems] = useState({});
    const updateItem = (key, newValue) => {
      setItems(prevItems => ({ ...prevItems, [key]: newValue }));
    };
  
    const getItem = (key) => {
      if(items) return items[key];
    };

    useEffect(() => {
      get_balances();
    }, [walletList]);
  
    async function get_balances(){
      if(walletList != null){
          for(const index in walletList){
              const item = walletList[index];
              fetch_balance(item, index);
          }
        }
    }

    function fetch_balance(item, index){
        fetch('https://private.neutron.ag/balance/' + item.publicKey)
              .then(response => {
                  return response.json(); 
              })
              .then(data => {
                  updateItem(item.publicKey, data);
              })
              .catch(error => {
                  console.log('Fetch Error:', error);
              });
    }

    function extractBalance(publicKey){
       const item = getItem(publicKey);
       if(item == null) return ;
       const balance = item.balance;
      //  const sol_amount = balance / 10 ** 9;
       return solNumber(balance);
    }




    function check_wallet_type(save_wallet_pubkey, pubkey, wallet_type){
      if(save_wallet_pubkey === pubkey) return (<input type="checkbox" name={pubkey} value={wallet_type} checked onClick={handleSaveType}/>);
      else return (<input type="checkbox" name={pubkey} onClick={handleSaveType} value={wallet_type} />);
    }

    const handleSaveType = (event) => {
        const { name, value, checked } = event.target;

        const address = name;
        const wallet_type = value;

        fetch('https://private.neutron.ag/set_wallet/' + wallet_type + "/" + address)
              .then(response => {
                  return response.json(); 
              })
              .then(data => {
                  if(data.success === 1) {
                    alert("Set " + address + " : " + wallet_type + " success!");
                    window.location.reload();
                  }
              })
              .catch(error => {
                  console.log('Fetch Error:', error);
              });

    }


    const [dataConfig, setDataConfig] = useState([]);

    useEffect(() => {
      const config_names = { "wallets" : ["JITO_wallet", "LP_wallet", "SWAP_wallet", "FEE_wallet"] };
      get_config(config_names);
    }, [walletList]);

    function get_config(params){
        fetch('https://private.neutron.ag/config/',
                {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(params)
                }
              )
              .then(response => {
                  return response.json(); 
              })
              .then(data => {
                 setDataConfig(data);
              })
              .catch(error => {
                  console.log('Fetch Error:', error);
              });
    }

    /***
     * CHECK API LIVE
     */

    const [apis, setAPis] = useState({});
    const updateApi = (key, newValue) => {
      setAPis(prevItems => ({ ...prevItems, [key]: newValue }));
    };
  
    const getApi = (key) => {
      if(apis) return apis[key];
    };

    const linkApis = ["private.neutron.ag", "private2.neutron.ag", "private3.neutron.ag", 
                      "api.tokencheck.io","api2.tokencheck.io", "api3.tokencheck.io", "tool.tokencheck.io",
                      "api.moonsnap.io" ];

    useEffect(() => {
        for(const base_link of linkApis){
            fetch('https://' + base_link + '/info')
                .then(response => {
                  return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
                })
                .then(data => {
                  if(data.success == 1)  updateApi(base_link, 1);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });

        }
        
      
    },[]);

   
    function showAPIstatus(status){
      if(status === 1) return (<div className='text-active'>ACTIVE</div>)
      else return (<div className='text-die'>DIE</div>)
    }


    return (
      <><Header/>
        <div className="mx-auto p-4 mt-4">
            <div className='w-full flex gap-4'>
                {
                   tokenList?.map((item, index) => {
                       return  (<div><a  className='underline underline-offset-2' href={`/tool?token=${item["mint"]}`}>{item["name"]}</a></div>)
                   })
                }
            </div>
            
            <div className="w-full md:flex  overflow-x-auto">
              <table className="table-auto w-full  mt-4 table-tokens" key="list">
                  <thead> 
                      <tr>
                        <th className="border border-gray-600 px-2 py-2">STT</th>
                        <th className="border border-gray-600 px-2 py-2">Name</th>
                        <th className="border border-gray-600 px-2 py-2">PublicKey</th>
                        
                        <th className="border border-gray-600 px-2 py-2">LP</th>
                        <th className="border border-gray-600 px-2 py-2">SWAP</th>
                        <th className="border border-gray-600 px-2 py-2">JITO</th>
                        <th className="border border-gray-600 px-2 py-2">FEE</th>
                        <th className="border border-gray-600 px-2 py-2">(SOL)</th>
                        <th className="border border-gray-600 px-2 py-2">Tokens</th>
                        <th className="border border-gray-600 px-2 py-2">Token Amount</th>
                      </tr>
                  </thead>
                  {walletList?.map((item, index) => {
                      return (

                        <tbody>
                          <tr key={index} className="hover:bg-slate-900">
                            <td className='px-2 py-2'>{index+1}</td>
                            <td className='px-2 py-2'>{item["name"]}</td>
                            <td className='px-2 py-2'>{shortTokenLink(item["publicKey"], "account")}</td>
                            <td className='px-2 py-2'>{check_wallet_type(dataConfig["LP_wallet"], item["publicKey"], "LP_wallet")}</td>
                            <td className='px-2 py-2'>{check_wallet_type(dataConfig["SWAP_wallet"], item["publicKey"], "SWAP_wallet")}</td>
                            <td className='px-2 py-2'>{check_wallet_type(dataConfig["JITO_wallet"], item["publicKey"], "JITO_wallet")}</td>
                            <td className='px-2 py-2'>{check_wallet_type(dataConfig["FEE_wallet"], item["publicKey"], "FEE_wallet")}</td>

                            <td className='px-2 py-2 float-right text-highlight'>{extractBalance(item['publicKey'])}</td>
                            <td className='px-2 py-2'><a className='underline underline-offset-2' href="">Check</a></td>
                            <td className='px-2 py-2'><TokenAmount publicKey={item["publicKey"]} tokenMint={token}/></td>                            
                          </tr>
                        </tbody>
                      )
                  })}
                </table>

            </div>

            <div className="w-full md:flex mt-10 gap-4">
                  <div className='w-1/3 max-sm:w-full x-board p-4'>
                    <div className='underline underline-offset-4'>Neutron API :</div>
                    <table className="table-auto w-full">
                      <tr><td>DATA</td><td>private.neutron.ag</td><td>{showAPIstatus(getApi("private.neutron.ag"))}</td> </tr>
                      <tr><td>SWAP</td><td>private2.neutron.ag</td><td>{showAPIstatus(getApi("private2.neutron.ag"))}</td> </tr>
                      <tr><td>TOKEN</td><td>private3.neutron.ag</td><td>{showAPIstatus(getApi("private3.neutron.ag"))}</td> </tr>
                    </table>
                    
                  </div>

                  <div className='w-1/3 max-sm:w-full x-board p-4'>
                    <div className='underline underline-offset-4'>TokenCheck API :</div>
                    <table className="table-auto w-full">
                      <tr><td>Load DB</td><td>api.tokencheck.io</td><td>{showAPIstatus(getApi("api.tokencheck.io"))}</td> </tr>
                      <tr><td>Request Web3</td><td>api2.tokencheck.io</td><td>{showAPIstatus(getApi("api2.tokencheck.io"))}</td> </tr>
                      <tr><td>Request Liquid</td><td>api3.tokencheck.io</td><td>{showAPIstatus(getApi("api3.tokencheck.io"))}</td> </tr>
                      <tr><td>Tool DB</td><td>tool.tokencheck.io</td><td>{showAPIstatus(getApi("tool.tokencheck.io"))}</td> </tr>
                    </table>

                  </div>

                  <div className='w-1/3 max-sm:w-full x-board p-4'>
                    <div className='underline underline-offset-4'>MoonSnap API :</div>
                    <table className="table-auto w-full">
                        <tr><td>Load DB</td><td>api.tokencheck.io</td><td>{showAPIstatus(getApi("api.moonsnap.io"))}</td> </tr>
                    </table>
                  </div>
                 
            </div>
           
        </div>
      </>
    )
  }
  
export default Tool;