import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import HistoryMenu from './HistoryMenu'; 
import {varTime, shortAddress, formatNumber} from '../../components/Helper';


const HistoryMint : React.FC = () => {

    const [mintPlusList, setMintPlusList] = useState([]);

    useEffect(() => {
        fetch('https://private.neutron.ag/mint_plus')
            .then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
            })
            .then(data => {
              
                 setMintPlusList(data);
            })
            .catch(error => {
              console.log('Fetch Error:', error);
            });
    },[]);

    

    return (
      <><Header/>
        <div className="mx-auto">
            <div className="w-ful  mt-4">
                <div className="w-full max-sm:w-full max-sm:flex justify-center">
                    <HistoryMenu/>
                </div>
                <div className="w-full mt-4 max-sm:w-full max-sm:mt-8">
                    
                       
                    <div className='w-full x-board p-4  overflow-x-auto'>
                        <table className='table-auto  w-full'>

                            <tr className='text-yellow-300'><td>No</td><td>Token</td><td>Owner</td><td>Supply</td><td>Decimals</td><td>Process</td><td>Time</td></tr>

                            {
                                mintPlusList.map((item, index) => {    

                                    return (<tr className="align-top">
                                                <td>{index + 1}</td> 
                                                <td >{item["mint"]}</td>
                                                <td >{shortAddress(item["owner"])}</td>
              
                                                <td >{formatNumber(item["supply"])}</td>
                                                <td >{item["decimals"]}</td>
                                                <td >{(item["process_time"]/1000).toFixed(0)} s</td>
                                                <td >{varTime(item["created_time"])}</td>
                                            </tr>)
                                })
                            }

                        </table>
                    </div>
                       

                </div>
            </div>
        </div>
      
      </>
    )
  }
  
export default HistoryMint