import React, { useState, useEffect } from 'react';
// import Logo from '../../neutron-logo.png';

function HomeArea(){
    const [isRotated, setRotated] = useState(false);

    const [serverData, setServerData] = useState(null);
    const [serverDBData, setServerDBData] = useState(null);
    const [tpsSolana, setTpsSolana] = useState(null);
    const [tpsTon, setTpsTon] = useState(null);

    const handleClick =() => {
        setRotated(!isRotated);
    }

    useEffect(() => {
        
        serverStatus().finally(() => {
            const intervalId = setInterval(() => {
                serverStatus();
                
            }, 10000);
        });

    },[]);


    async function serverStatus(){
        setServerData(null);
        setServerDBData(null);
        
        fetch('https://ps.neutron.ag/server.php')
            .then(response => {
                return response.text(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
            })
            .then(data => {
            
                setServerData(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });

        fetch('https://psdb.neutron.ag/server.php')
            .then(response => {
                return response.text(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
            })
            .then(data => {
            
                setServerDBData(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
            
       
        
    }

    return (
        <div className="w-full text-white text-center">
            <div className="text-5xl mt-20">NEUTRON</div>
            <div className="text-xl color-main">Aggregator</div>
            <div className="text-center mt-10"><img  alt="neutron"
                                                className={`w-60 max-sm:100 mx-auto  ${isRotated ? 'animate-spin animate-spin-logo' : 'animate-pulse'}`}
                                                onClick={handleClick}
                                                src="/neutron-logo.png" />
            </div>

            <div className='w-full mx-auto fixed bottom-10'>
                <div className='flex justify-center gap-8'>
                    <div className='x-board p-2 rounded w-60'>{serverData}<div className='text-xs'>FRONT</div></div>
                    <div className='x-board p-2 rounded w-60'>{serverDBData}<div className='text-xs'>DATABASE</div></div>
                </div>
            </div>
        
        </div>
    )
};

export default HomeArea