import React, { useState, useEffect } from 'react';
import ToolNavigation from './ToolNavigation'; 
import Log from '../tool-swap/Log';
import WalletBoard from './WalletBoard';


const UpdateTokenArea : React.FC= () => {
    const [logParam, setLogParam] = useState<any>(null);
    const [logTitle, setLogTitle] = useState<any>(null);
    const [timeTaken, setTimeTaken] = useState(0);

    const [Token, setToken] = useState(null); //mint Address
    const [TokenMeta, setTokenMeta] = useState(null); //mint Address

    const handleLoadClick = () => {
        if(Token != null){
            //fetch token meta
            fetch('https://tool.tokencheck.io/token/meta-umi/' + Token)
                .then(response => {
                // Xử lý khi nhận được response từ server
                return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
                })
                .then(data => {
                    setTokenMeta(data);
                    setLogParam(data);
                })    
                .catch(error => {
                    // Xử lý khi gặp lỗi trong quá trình fetch
                    console.log('Fetch Error:', error);
                });
        }
    }



    const handleTokenChange = (e) => {
        const token = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', token);
        setToken(token);
    }

    const [Name, setName] = useState(null); 

    const handleNameChange = (e) => {
        const value = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', value);
        setName(value);
    }

    const [Symbol, setSymbol] = useState(null); 

    const handleSymbolChange = (e) => {
        const value = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', value);
        setSymbol(value);
    }

    const [Image, setImage] = useState(null); 
    const handleImageChange = (e) => {
        const value = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', value);
        setImage(value);
    }

    const [Description, setDescription] = useState(null); 
    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', value);
        setDescription(value);
    }

    const handleUpdateClick = (e) => {
        console.log(Name);
        console.log(Symbol);
        console.log(Image);
        console.log(Description);

        const params = {};
        if(Name !== null) params["name"] = Name;
        if(Symbol !== null) params["symbol"] = Symbol;
        if(Image !== null) params["image"] = Image;
        if(Description !== null) params["description"] = Description;
          
        if(Object.keys(params).length > 0 && Token != null){
            //fetch token meta
            fetch('https://tool.tokencheck.io/token/update/' + Token,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params)
                })
                .then(response => {
                    // Xử lý khi nhận được response từ server
                    return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
                })
                .then(data => {
                   
                })    
                .catch(error => {
                    // Xử lý khi gặp lỗi trong quá trình fetch
                    console.log('Fetch Error:', error);
                });
        }
    }

    return (
            
                <div className="w-full md:flex gap-4">
                    <div className="md:w-1/2 max-sm:w-full">
                        <div className='w-full x-board p-4'>
                            <WalletBoard/>
                        </div>
                        <div className='w-full x-board p-4 mt-4'>
                            <table className="table-auto w-full mx-auto">
                                <tbody>
                                    <tr><td>Token</td>
                                        <td><div className="flex">
                                                <input
                                                    onChange={handleTokenChange}
                                                    className="w-full text-black p-1.5" type="text"/> 
                                                <button 
                                                    onClick={handleLoadClick}
                                                    className='border px-2 text-orange-500 whitespace-nowrap'>META</button>
                                            </div>
                                        </td>
                                    </tr>
                                    

                                    <tr><td>Name</td><td><input 
                                                    onChange={handleNameChange}
                                                    className="w-full text-black p-1.5" type="text"/> </td></tr>
                                    <tr><td>Symbol</td><td><input 
                                                    onChange={handleSymbolChange}
                                                    className="w-full text-black p-1.5" type="text"/> </td></tr>
                                    <tr><td>Image</td><td><input 
                                                    onChange={handleImageChange}
                                                    className="w-full text-black p-1.5" type="text"/> </td></tr>
                                    <tr><td>Description</td><td><input 
                                                    onChange={handleDescriptionChange}
                                                    className="w-full text-black p-1.5" type="text"/> 
                                                
                                        </td>
                                    </tr>
                                    <tr><td></td><td> <button 
                                                        onClick={handleUpdateClick}
                                                        className='button text-sm text-black  bg-yellow-300  p-2 pl-4 pr-4'>UPDATE META</button></td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="md:w-1/2 max-sm:w-full max-sm:mt-4">
                        <Log logParam ={logParam} logTitle = {logTitle} timeTaken = {timeTaken}/>
                    </div>
                </div>
           

    );

}

export default UpdateTokenArea