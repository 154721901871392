import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

export default function ToolNavigation() {
    const [activeItem, setActiveItem] = useState('tool'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        // update state based on URL
        if (location.pathname === '/tool-swap/') {
          setActiveItem('tool');
        } 
        else if (location.pathname === '/tool-swap/param') {
          setActiveItem('param');
        } 
        else if (location.pathname === '/tool-swap/add-liquid') {
          setActiveItem('add-liquid');
        }
        else if (location.pathname === '/tool-swap/buy-sell') {
          setActiveItem('buy-sell');
        }
        else if (location.pathname === '/tool-swap/remove-liquid') {
          setActiveItem('remove-liquid');
        }
        else if (location.pathname === '/tool-swap/market') {
          setActiveItem('market');
        }
      
        

      }, [location]); // update when location changes

    return (
        //navigation
        <div className="x-board p-4 grid gap-4 max-sm:flex">
            <div className="flex">
                <Link to="/tool-swap/param" className={activeItem === 'param' ? 'text-highlight' : ''}>Param</Link>
            </div>
            
            <div className="flex">
                <Link to="/tool-swap/buy-sell" className={activeItem === 'buy-sell' ? 'text-highlight' : ''}>Buy + Sell</Link>
            </div>

            <div className="flex">
                <Link to="/tool-swap/add-liquid" className={activeItem === 'add-liquid' ? 'text-highlight' : ''}>Liquid + Buy</Link>
            </div>
            <div className="flex">
                <Link to="/tool-swap/remove-liquid" className={activeItem === 'remove-liquid' ? 'text-highlight' : ''}>Remove Liquid</Link>
            </div>

            <div className="flex">
                <Link to="/tool-swap/market" className={activeItem === 'market' ? 'text-highlight' : ''}>Market</Link>
            </div>
          
        </div>
    );

}
