import React from 'react';
import HomeArea from './HomeArea';
import { Helmet } from 'react-helmet';
import Logo from '../../neutron-logo.png';

function Home(){
    return (
      <>
        <Helmet>
            <link rel="icon" href={Logo} />
            <link rel="apple-touch-icon" href={Logo} />
            <style>
                {
                    `@font-face {
                        font-family: "Nulshock";
                        src: url("/fonts/nulshock_bd.otf") format("truetype");
                        font-weight: normal;
                        font-style: normal;
                    }
                    
                    body {
                        font-family: "Nulshock", sans-serif;
                        background-color : #0a1520;
                        height: 100vh;

                        background-repeat: no-repeat;
                        background-position: top;
                    }
                    
                    .color-main {
                        color : #31d99c;
                    }
                    `
                }
            </style>
        </Helmet>
        <main className="h-full">
            <HomeArea/>
        </main>
      </>
    )
}
  
export default Home