import React, { useState, useEffect } from 'react';
import CreateTokenArea from './CreateTokenArea';
import Header from '../../components/header/Header';

import ToolNavigation from './ToolNavigation'; 

const CreateToken : React.FC = () => {
    return (
      <><Header/>
        <div className="mx-auto mt-4">
            <div className="w-full md:flex gap-4">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                    <CreateTokenArea/>
                </div>
            </div>
        </div>
      </>
    )
  }
  
export default CreateToken