import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import * as web3 from '@solana/web3.js'
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useState, useEffect } from 'react'

import * as buffer from "buffer";
window.Buffer = buffer.Buffer;


export default function SendWalletArea(){
    const commandList = ["TransactionInstruction <-- fromPubkey, toPubkey, lamports", "Transaction <-- TransactionInstruction", "sendTransaction <-- Transaction, connection"]
    const [timeTaken, setTimeTaken] = useState(null);

    const [txSig, setTxSig] = useState('');
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
   
    const link = () => {
        return txSig ? `https://explorer.solana.com/tx/${txSig}?cluster=mainnet-beta` : ''
    }

    const sendSol = event => {
        event.preventDefault()
        if (!connection || !publicKey) { return }
        

        const transaction = new web3.Transaction()
        const recipientPubKey = new web3.PublicKey(event.target.recipient.value)

        const sendSolInstruction = web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: recipientPubKey,
            lamports: LAMPORTS_PER_SOL * event.target.amount.value
        })
        
        transaction.add(sendSolInstruction);


        const startTime = performance.now();

        sendTransaction(transaction, connection).then(sig => {
            setTxSig(sig)
        })

        const endTime = performance.now();
        setTimeTaken(endTime - startTime);

        

    }




    return (
        <div className='md:flex gap-4'>
            <div className='w-1/2 max-sm:w-full x-board p-4'>
                {
                    publicKey ?
                        <form onSubmit={sendSol} className='max-w-sm mx-auto w-full'>
                            <label className="block" htmlFor="amount">Amount (in SOL) to send:</label>
                            <input className="p-1.5 w-full" id="amount" type="text"  placeholder="e.g. 0.1" required />
                            <br />
                            <label className="block" htmlFor="recipient">Send SOL to:</label>
                            <input className="p-1.5 w-full" id="recipient" type="text"  placeholder="e.g. 4Zw1fXuYuJhWhu9KLEYMhiPEiqcpKd6akw3WRZCv84HA" required />
                            <div className="mt-4">
                                <button className="text-black  bg-yellow-300 hover:bg-blue-800 focus:ring-4 px-5 py-2.5" type="submit">Send</button>
                            </div>
                        </form> :
                        <span>Connect Your Wallet</span>
                }
            </div>
            <div className='w-1/2 max-sm:w-full max-sm:mt-4 x-board p-4'>
                <div>
                    <span>Transaction : </span>
                {
                    txSig ? <a target="_blank" href={link()}>{txSig}</a>:null
                }
                </div>

                <div>
                    <span>P-Time : </span> {timeTaken?<div>{timeTaken}</div>: null}
                </div>
                <div className='mt-4'>
                    <p>Command:</p>
                    {
                        commandList?.map((item, index) => {
                            return (<div className='text-sm'>{index + 1}. {item}</div>);
                        })
                    }
                </div>
            </div>
        </div>
    )
}