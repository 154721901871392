import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import {solNumber, shortTokenLink, shortTokenLink2} from '../components/Helper';


const Tranx : React.FC = () => {

    const [tranxList, setTranxList] = useState([]);

    useEffect(() => {
        fetch('https://private.neutron.ag/tranxs')
            .then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
            })
            .then(data => {
              
                 setTranxList(data);
            })
            .catch(error => {
              console.log('Fetch Error:', error);
            });
    },[]);

    const [isVisible, setIsVisible] = useState(!document.hidden);

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsVisible(!document.hidden);
            console.log("isVisible = " + isVisible);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);


    return (
        <><Header/>
            <div className="mx-auto p-4 mt-4">
                
                <div className="w-full md:flex  overflow-x-auto">
                <table className="table-auto w-full  mt-4 table-tokens" key="list">
                    <thead> 
                      <tr>
                        <th className="border border-gray-600 px-2 py-2">STT</th>
                        <th className="border border-gray-600 px-2 py-2">Block</th>
                        <th className="border border-gray-600 px-2 py-2">Tx</th>
                        <th className="border border-gray-600 px-2 py-2">SOL</th>
                        <th className="border border-gray-600 px-2 py-2">Amount(B)</th>
                        <th className="border border-gray-600 px-2 py-2">Mint</th>
                        <th className="border border-gray-600 px-2 py-2">Type</th>
                        <th className="border border-gray-600 px-2 py-2">Wallet</th>
                        <th className="border border-gray-600 px-2 py-2">Tx(J)</th>
                        <th className="border border-gray-600 px-2 py-2">JITO(L)</th>
                        <th className="border border-gray-600 px-2 py-2">P-Time</th>
                        <th className="border border-gray-600 px-2 py-2">Time</th>
                      </tr>
                  </thead>

                {
                    tranxList?.map((item, index) => {

                        return (<tr key={index} className="hover:bg-slate-900">
                                    <td>{index+1}</td>
                                    <td>{item["block"]}</td>
                                    <td>{shortTokenLink(item["tx"],"tx")}</td>
                                    <td>{solNumber(item["sol_amount"])}</td>
                                    <td>{Math.floor(item["mint_amount"] / 10 ** 6)}</td>
                                    <td>{shortTokenLink2(item["mint"],"token")}</td>
                                    <td>{item["is_buy"]==1?'Buy':"Sell"}</td>
                                    <td>{shortTokenLink2(item["wallet"],"account")}</td>
                                    <td>{shortTokenLink(item["jito_tx"],"tx")}</td>
                                    <td>{item["jito_fee"]}</td>
                                    <td>{item["process_time"]}</td>
                                    <td>{item["created_time"]}</td>
                                </tr>);
                        
                    })
                }
                </table>
                </div>
            </div>
        </>
    )
}

export default Tranx;
