import React, { useState, useEffect } from 'react';
import CreateTokenArea from './CreateTokenArea';
import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import Log from '../tool-swap/Log';
import WalletBoard from './WalletBoard';
import {solNumber, formatNumber, formatSupply, realSupply} from '../../components/Helper';


const MintToken : React.FC = () => {

    const [logParam, setLogParam] = useState<any>(null);
    const [logTitle, setLogTitle] = useState<any>(null);
    const [timeTaken, setTimeTaken] = useState(0);

    const [mintAddress, setMintAddress] = useState(null); //mint Address
    const [tokenInfo, setTokenInfo] = useState<any>(null);
    const [mintPlus, setMintPlus] = useState(0);


    const handleTokenChange = (e) => {
        const token = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', token);
        setMintAddress(token);
    }

    const handleLoadClick = () => {
        if(mintAddress != null){
            //fetch token meta
            fetch('https://tool.tokencheck.io/token/request-info/' + mintAddress)
                .then(response => {
                // Xử lý khi nhận được response từ server
                return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
                })
                .then(data => {
                    setLogParam(data);
                    setTokenInfo(data);
                })    
                .catch(error => {
                    // Xử lý khi gặp lỗi trong quá trình fetch
                    console.log('Fetch Error:', error);
                });
        }
    }

    const handleMintPlusChange = (e) => {
        const _mintPlus = e.target.value;
        setMintPlus(_mintPlus);
    }

    const handleX1000Click = () => {
        const _realSupply = realSupply(tokenInfo?.supply , tokenInfo?.decimals);
        if(_realSupply !== "")
            {
                const _mintPlus = 1000 * _realSupply;
                setMintPlus(_mintPlus);
            }
    }

    /***
     * MINT AREA
     */

    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [mintTokenButtonName, setMintTokenButtonName] = useState("MINT+");

    const handleMintToken = () => {

        setButtonDisabled(true);
        setMintTokenButtonName("MINTING...");

        const params = {mint : mintAddress, decimals : tokenInfo?.decimals, supply : mintPlus};

        // alert(tokenInfo.supply);
        fetch('https://private3.neutron.ag/solana/mint_token',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }
        )
        .then(response => {
            return response.json(); 
        })
        .then(response => {
             const data = response.data;
             setLogParam(data);
             setTimeTaken(response.time);

             setMintTokenButtonName("MINT+");
        })
        .catch(error => {
            console.log('Fetch Error:', error);
        });
    }



    return (
      <><Header/>
        <div className="mx-auto mt-4">
            <div className="w-full md:flex gap-4">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8 flex gap-4">
                    <div className="md:w-1/2 max-sm:w-full">
                        <div className='w-full x-board p-4'>
                            <WalletBoard/>
                        </div>
                        <div className='w-full x-board p-4 mt-4'>
                            <table className="table-auto w-full mx-auto">
                                <tbody>
                                    <tr><td>Token</td>
                                        <td><div className="flex">
                                                <input
                                                    onChange={handleTokenChange}
                                                    className="w-full text-black p-1.5" type="text"/> 
                                                <button 
                                                    onClick={handleLoadClick}
                                                    className='border px-2 text-orange-500 whitespace-nowrap'>INFO</button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr><td>Amount</td><td>
                                            <div>Decimals : {tokenInfo?.decimals}</div>
                                            <div>Supply : {formatSupply(tokenInfo?.supply , tokenInfo?.decimals)}</div>    
                                            </td>
                                                
                                    </tr>
                                    <tr><td>Supply+</td><td>
                                        <div className='flex'><input
                                                onChange={handleMintPlusChange}
                                                value={mintPlus}
                                                className="w-full text-black p-1.5" type="text"/>
                                            <button onClick={handleX1000Click}
                                                    className='border px-2 text-orange-500 whitespace-nowrap'>X1000</button>
                                        </div>

                                        <div className='text-yellow-300'>{formatNumber(mintPlus)}</div>

                                        </td>
                                    </tr>
                                    <tr><td></td><td><button className='button text-sm text-black  bg-yellow-300  p-2 pl-4 pr-4' onClick={handleMintToken} disabled={isButtonDisabled}>{mintTokenButtonName}</button></td></tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="md:w-1/2 max-sm:w-full max-sm:mt-4">
                        <Log logParam ={logParam} logTitle = {logTitle} timeTaken = {timeTaken}/>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
  }
  
export default MintToken