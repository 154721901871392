import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Home from './pages/home/Home';
import Tool from './pages/Tool';
import ToolToken from './pages/tool-token/ToolToken';
import ToolSwap from './pages/tool-swap/ToolSwap';

import SendSol from './pages/tool-token/SendSol';
import SendToken from './pages/tool-token/SendToken';
import CreateToken from './pages/tool-token/CreateToken';
import UpdateToken from  './pages/tool-token/UpdateToken';
import SendWallet from './pages/tool-token/SendWallet';
import HistoryToken from './pages/history/HistoryToken';
import MintToken from './pages/tool-token/MintToken';
import HistoryMint from './pages/history/HistoryMint';

import Param from './pages/tool-swap/Param';
import BuySell from './pages/tool-swap/BuySell';
import AddLiquid from './pages/tool-swap/AddLiquid';
import RemoveLiquid from './pages/tool-swap/RemoveLiquid';
import Market from './pages/tool-swap/Market';
import Tranx from './pages/Tranx';
import ParamToken from './pages/tool-token/Param';

const App: React.FC = () => {
  return (
    <div className="App">
        <Router>
          <Routes>
              <Route  path="/"  element={<Home/>}/> 
              <Route path="/tool-token/send-sol" element={<SendSol/>} />
              <Route path="/tool-token/send-token" element={<SendToken/>} />
              <Route path="/tool-token/create-token" element={<CreateToken/>} />
              <Route path="/tool-token/update-token" element={<UpdateToken/>} />
              <Route path="/tool-token/send-wallet" element={<SendWallet/>} />
              <Route path="/tool-token/param" element={<ParamToken/>} />
              <Route path="/tool-token/mint-token" element={<MintToken/>} />
              
              <Route path="/history/mint" element={<HistoryMint/>} />
              <Route path="/history/token" element={<HistoryToken/>} />
              <Route path="/history" element={<HistoryToken/>} />

              <Route path="/tool-token" element={<ToolToken/>} />
              
              <Route path="/tool-swap/param" element={<Param/>} />
              <Route path="/tool-swap/buy-sell" element={<BuySell/>} />
              <Route path="/tool-swap/add-liquid" element={<AddLiquid/>} />
              <Route path="/tool-swap/remove-liquid" element={<RemoveLiquid/>} />
              <Route path="/tool-swap/market" element={<Market/>} />

              <Route path="/tool-swap" element={<ToolSwap/>} />
              <Route path="/tool" element={<Tool/>} />
              <Route path="/tranx" element={<Tranx/>} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;


