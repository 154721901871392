export function solNumber(lamports, showZero){
    if(lamports == null) return "";
    if(lamports === 0 && showZero === false) return "";

    const sol = lamports / 10 ** 9;

    if (Number.isInteger(sol)) {
        return sol.toLocaleString('en-US');
    }

    var new_sol; 
    if (sol >=  10) 
        new_sol =  sol.toFixed(1);
    else if (sol >= 1) 
        new_sol = sol.toFixed(2);
    else 
        new_sol = sol.toFixed(3);

    return new_sol.toLocaleString('en-US');
}

export function solBeautify(sol){
    
    if (Number.isInteger(sol)) {
        return sol.toLocaleString('en-US');
    }

    var new_sol; 
    if (sol >= 0.1) 
        new_sol =  sol.toFixed(1);
    else if (sol >= 0.01) 
        new_sol = sol.toFixed(2);
    else 
        new_sol = sol.toFixed(3);

    return new_sol.toLocaleString('en-US');
}

export  function solscanLink(address, type ){ //account tx token
    return 'https://solscan.io/'+ type + '/' + address;
}

export  function shortAddress(address) {
    if(address == null || address.length < 10) return "";
    const firstForChars = address.substr(0,5);
    const lastForChars  = address.substr(-4);
    return firstForChars + "..." + lastForChars;
};

export function shortTokenLink(address, type){
     if(address == null || address.length < 10) return "";
     const short_Token = shortAddress(address);
     const sol_scanLink = solscanLink(address, type);
     return (<a className="text-orange-500" target="_blank" rel="noreferrer"  href={sol_scanLink}>{short_Token}</a>);
}

export  function shortAddress2(address) {
    if(address == null || address.length < 10) return "";
    const firstForChars = address.substr(0,5);
    return firstForChars + "...";
};

export function shortTokenLink2(address, type){
     if(address == null || address.length < 10) return "";
     const short_Token = shortAddress2(address);
     const sol_scanLink = solscanLink(address, type);
     return (<a className="text-orange-500" target="_blank" rel="noreferrer"  href={sol_scanLink}>{short_Token}</a>);
}


export function tokenLink(address, type){
    const sol_scanLink = solscanLink(address, type);
    return (<a className="text-orange-500" target="_blank" rel="noreferrer"  href={sol_scanLink}>{address}</a>);
}

export function formatNumber(amount) {
    if(amount == null) return "";
    // Chuyển đổi số sang chuỗi
    let strAmount = amount.toString();
  
    // Tách phần nguyên và phần thập phân
    let parts = strAmount.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  
    // Định dạng phần nguyên
    let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    // Kết hợp lại và trả về chuỗi đã định dạng
    return formattedInteger + decimalPart;
}


export function formatSupply(supply, decimals){
    const _realSupply = realSupply(supply, decimals);
    if(_realSupply !== "") 
        return formatNumber(_realSupply);
}
export function realSupply(supply, decimals){
    if(decimals == null) return "";
    if(supply == null) return "";
    const amount = supply / 10 ** decimals;
    return amount;
}

export function varTime(time){
    if(time == null || time == "") return '-';
    const current = new Date();
    const current_unix = current.getTime()/1000;
    const distance_time = current_unix - time;
    var express_time = '';

    if(distance_time < 60) express_time = Math.floor(distance_time)+ "s ";
    else if(distance_time < 60 * 60) express_time = Math.floor(distance_time/60) + "' ";
    else if(distance_time < 24 * 60 * 60)  express_time = Math.floor(distance_time/3600) + "h ";
    else express_time = Math.floor(distance_time/24/3600) + "d ";
    return express_time ;
}