
const Log = ({logParam, logTitle, timeTaken}) => {
    
    return (
        <div className="w-full x-board p-4 text-xs">
            <div className='text-highlight'>Task : {logTitle}</div>
            <div className='text-orange-500'>Time : {Math.floor(timeTaken)} (ms)</div>
            <div className='whitespace-normal overflow-x-auto'>
                <pre>{logParam?JSON.stringify(logParam,undefined, 2) :""}</pre>  
            </div>
        </div>
    )

}

export default Log