import React, { useState, useEffect } from 'react';
import {shortTokenLink, solBeautify, solNumber , tokenLink} from '../../components/Helper';
import Log from './Log';


const MarketID = ({marketID , buyAmount = 0.01, showLog = false }) => {
    const [marketId, setMarketId] = useState('');
    const [PoolKeys, setPoolKeys] = useState<any>(null);
    const [PoolInfo, setPoolInfo] = useState<any>(null);

    const [logParam, setLogParam] = useState<any>(null);
    const [logTitle, setLogTitle] = useState<any>(null);
    const [timeTaken, setTimeTaken] = useState(0);

    const [tokenMint, setTokenMint] = useState('');


    const handleMarketIdChange = (e) => {
        setMarketId(e.target.value);
    };

    useEffect(() => {
        setMarketId(marketID);
    },[marketID]);

    const [baseData, setBaseData] = useState<any>(null);
    const [quoteData, setQuoteData] = useState<any>(null);

    const [baseMint, SetBaseMint] = useState<any>(null);
    const [quoteMint, SetQuoteMint] = useState<any>(null);

    useEffect(() => {
        if(PoolKeys?.baseMint) fetch_meta(PoolKeys?.baseMint , "base");
        if(PoolKeys?.quoteMint) fetch_meta(PoolKeys?.quoteMint , "quote");

    },[baseMint, quoteMint]);

    function fetch_meta(mint : any, type : any){
        fetch('https://api.snapcheck.xyz/token/mint/' + mint)
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                if(data != null){
                    if(type === "base"){
                        setBaseData(data);
                    }
                    else if(type === "quote"){
                        setQuoteData(data);
                    }
                }
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }


    useEffect(() => {
        // if(marketId) fetch_poolKeys(marketId);
    },[marketId]);


    

    const handleMarketId = () => {
        if(marketId) fetch_poolKeys(marketId);
    }

    function fetch_poolKeys(_marketId){
        setTimeTaken(0);
        setPoolKeys(null);
        setBaseData(null);
        setQuoteData(null);

        fetch('https://private2.neutron.ag/pool_info/' + _marketId)
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                setTimeTaken(data.time);
                setLogParam(data.data);

                setPoolInfo(data.data);
                setPoolKeys(data.data.poolKeys);
                SetBaseMint(data.data.poolKeys.baseMint);
                SetQuoteMint(data.data.poolKeys.quoteMint);

            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }

    const handleRequestMarket = () =>{
        if(marketId){
            setLogParam(null);
            setTimeTaken(0);

            fetch('https://private2.neutron.ag/market_info/' + marketId)
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setLogTitle("[getAccountInfo, MARKET_STATE_LAYOUT_V3] --> market_info --> [create pool]");
                    setLogParam(data.data);
                    setTimeTaken(data.time);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    }

    const handleRequestPoolKeys = () =>{
        if(marketId){
            setLogParam(null);
            setTimeTaken(0);

            fetch('https://private2.neutron.ag/pool_keys/' + marketId)
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setLogTitle("[market_info, jsonInfo2PoolKeys] --> poolKeys --> [remove_liquid, Swap Ix]");
                    setLogParam(data.data);
                    setTimeTaken(data.time);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    }
    
    const handleRequestSwapIns = () => {
        if(marketId){
            setLogParam(null);
            setTimeTaken(0);

            let params = { "market_id" : marketId};

            fetch('https://private2.neutron.ag/build_swap_ins/',{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(params)
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setLogTitle("[market_id --> poolKeys + sol_amount --> [Swap Ix]");
                    setLogParam(data);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    }

    const handleRequestSwapIns2 = () => {
        if(marketId){
            setLogParam(null);
            setTimeTaken(0);

            let params = { "market_id" : marketId};

            fetch('https://private2.neutron.ag/build_swap_ins2/',{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(params)
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setLogTitle("[market_id --> poolKeys + sol_amount --> [Swap Ix]");
                    setLogParam(data.swap_ix);
                    setTimeTaken(data.time);

                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    }

    const handleRequestBuy = () => {
        if(marketId){
            setLogParam(null);
            setTimeTaken(0);

            let params = { "market_id" : marketId};

            fetch('https://private2.neutron.ag/swap_buy/',{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(params)
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setLogTitle("[market_id --> poolKeys + sol_amount --> [Swap Ix]");
                    setLogParam(data);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    }

    const handleRequestBuy2 = () => {
        if(marketId){
            setLogParam(null);
            setTimeTaken(0);

            let params = { "market_id" : marketId};

            fetch('https://private2.neutron.ag/swap_buy2/',{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(params)
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setLogTitle("[market_id --> poolKeys + sol_amount --> [Swap Ix]");
                    setLogParam(data);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    }

    const handleTokenMintChange = (e) => {
        setTokenMint(e.target.value);
    }   

    const checkTokenMint1 = () =>{
        setTimeTaken(0);
        setLogParam(null);
        setLogTitle("[OpenBook Id, mint --> getProgramAccounts, MARKET_STATE_LAYOUT_V3");


        if(tokenMint != ''){
            fetch('https://private2.neutron.ag/check_mint1/' + tokenMint)
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setTimeTaken(data.time);
                    setLogParam(data.data);

                    if(data.data != null){
                        const data_0 = data.data[0];
                        setMarketId(data_0["ownAddress"]);
                    }

                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
        
    }

    const checkTokenMint2 = () =>{
        setTimeTaken(0);
        setLogParam(null);
        setLogTitle("[raydium Id, mint --> getProgramAccounts, LIQUIDITY_STATE_LAYOUT_V4");


        if(tokenMint != ''){
            fetch('https://private2.neutron.ag/check_mint2/' + tokenMint)
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setTimeTaken(data.time);
                    setLogParam(data.data);

                    if(data.data != null){
                        const data_0 = data.data[0];
                        setMarketId(data_0["marketId"]);
                    }
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
        
    }
    
    return (
        <>
            <div className='w-full x-board p-4'>   
                <div className="w-full">Market ID</div>
                <div className="flex mt-4">
                    <input className="w-full text-black px-2 py-1" type="text" onChange={handleTokenMintChange} 
                        defaultValue={tokenMint}/>
                    <button className="border px-2 text-orange-500" onClick={checkTokenMint1}>Check [Market]</button>   
                    <button className="border px-2 text-orange-500" onClick={checkTokenMint2}>Check [Liquid]</button>
                </div>

                <div className="w-full flex">
                    <div className="w-full">Market ID</div>
                    <div className='float-right'><a className="underline" target="_blank" href={`https://dexscreener.com/solana/${PoolKeys?.id}`}>Dexscreener</a></div>
                </div>       
               
                <div className="flex mt-4">
                    <input className="w-full text-black px-2 py-1" type="text" onChange={handleMarketIdChange} 
                        defaultValue={marketID}/>
                    <button className="border px-2 text-orange-500" onClick={handleMarketId}>Pool Info</button>           
                </div>

                <div className="w-full mt-4 flex">
                    <div className='w-full'>
                        <div>Amm Id (Pair): <span className='text-highlight'>{PoolKeys?.id}</span></div>
                        <div>baseVault : {shortTokenLink(PoolKeys?.baseVault, "account")}</div>
                        <div>quoteVault : {shortTokenLink(PoolKeys?.quoteVault, "account")}</div>
                        <div>lpVault : {shortTokenLink(PoolKeys?.lpVault, "account")}</div>
                    </div>
                
                </div>

                <div className='flex w-full gap-4 mt-4 mb-4'>
                    
                    <div className="w-1/2 x-board body-bg p-4 flex">
                    
                        <div className='w-full'>
                            <div>Base : {shortTokenLink(PoolKeys?.baseMint, "token")}</div>
                            <div>Decimals : {PoolKeys?.baseDecimals}</div>
                            <div>LP Decimals : {PoolKeys?.lpDecimals}</div>
                        </div>
                        
                        <div className='float-right text-center'>
                            {
                                baseData?(
                                    <>
                                        <a target="_blank" href={`https://snapcheck.xyz/token/${baseData.mintA}`}>
                                            <img className="w-20" src={baseData.token_image}/>
                                            <div className='whitespace-nowrap' >{baseData.token_name}</div>
                                        </a>
                                    </>
                                )   
                                :("")
                            }
                        </div>
                    </div>

                    <div className="w-1/2 x-board body-bg p-4 flex">
                        <div className='w-full'>
                            <div>Quote : {shortTokenLink(PoolKeys?.quoteMint, "token")}</div>
                            <div>Decimals : {PoolKeys?.quoteDecimals}</div>
                        </div>
                        
                        <div className='float-right'>
                            {
                                quoteData?(
                                    <>
                                        <a href={`https://snapcheck.xyz/token/${quoteData.mintA}`}>
                                            <div className="float-right"><img className="w-20" src={quoteData.token_image}/></div>
                                            <div className='whitespace-nowrap' >{quoteData.token_name}</div>
                                        </a>
                                    </>
                                )   
                                :("")
                            }
                        </div>
                    
                    </div>
                
                </div>
                
                
                {/* <div className='w-full flex'>
                    Time : <div className='text-orange-500 ml-2'>{Math.floor(timeTaken)} (ms)</div>
                </div> */}
                {
                    showLog?(
                        <>
                            {/* <div className='w-full'>
                                Market_id : [getAccountInfo, MARKET_STATE_LAYOUT_V3]
                            </div> */}
                            <div className='w-full mt-4 flex gap-4'>
                                <button className="border px-2 text-orange-500" onClick={handleRequestMarket}>Market</button>  
                                <button className="border px-2 text-orange-500" onClick={handleRequestPoolKeys}>PoolKeys</button> 
                                <button className="border px-2" onClick={handleRequestSwapIns}>Swap_ix : {buyAmount} SOL</button> 
                                <button className="border px-2 text-orange-500" onClick={handleRequestSwapIns2}>Swap_ix 2 : {buyAmount} SOL</button> 
                                <button className="border px-2 " onClick={handleRequestBuy}>Buy : {buyAmount} SOL</button>
                                <button className="border px-2 text-orange-500" onClick={handleRequestBuy2}>Buy2 : {buyAmount} SOL</button>

                                <button className="border px-2 text-orange-500" onClick={handleRequestBuy2}>Sell : 100%</button>
                            </div>
                            
                        </>
                    )
                    :("")
                }
                                    
                                
            </div>

            {
                 showLog?(
                    <div className='w-full x-board mt-4'>
                        <Log logParam ={logParam} logTitle = {logTitle} timeTaken = {timeTaken}/>
                    </div>
                 ):("")  
            }
                    
        </>
    )

}

export default MarketID