import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../../neutron-logo-core.png';

export default function MenuNavigation() {
    const [activeItem, setActiveItem] = useState('home'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        if (location.pathname === '/') {
          setActiveItem('home');
        } 
        else if (location.pathname.includes('/tool-token')) {
          setActiveItem('tool-token');
        } 
        else if (location.pathname.includes('/tool-swap')) {
            setActiveItem('tool-swap');
        } 
        else if (location.pathname.includes('/tool-claim')) {
            setActiveItem('tool-claim');
        } 
        else if (location.pathname.includes('/history')) {
            setActiveItem('history');
        } 
        
      }, [location]); 

    return (
        //navigation
        <>
            <div className="">
                <Link to="/tool" className={activeItem === 'home' ? 'text-highlight' : ''}>
                    <img className='max-sm:w-12 md:w-8 animate-spin animate-spin-logo' alt="neutron" src={Logo}/> 
                </Link>
            </div>

            


            <div className="">
                <Link to="/tool-token" className={activeItem === 'tool-token' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Token</span></Link>
            </div>
            <div className="">
                <Link to="/tool-swap" className={activeItem === 'tool-swap' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Swap</span></Link>
            </div>

            <div className="">
                <Link to="/tool-swap" className={activeItem === 'tool-pump' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Pump</span></Link>
            </div>

            <div className="">
                <Link to="/history" className={activeItem === 'history' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">History</span></Link>
            </div>

            {/* <div className="">
                <Link to="/tool-claim" className={activeItem === 'tool-claim' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Claim</span></Link>
            </div> */}

            
        </>
    );

}
