import React, { useState, useEffect } from 'react';
import {solNumber, formatNumber} from '../../components/Helper';
import Log from '../tool-swap/Log';
import { json } from 'body-parser';
import WalletBoard from './WalletBoard';

const CreateTokenArea : React.FC = () => {
    const [logParam, setLogParam] = useState<any>(null);
    const [logTitle, setLogTitle] = useState<any>(null);
    const [timeTaken, setTimeTaken] = useState(0);


    const [tokenClone, setTokenClone] = useState('');

    const [tokenInfo, setTokenInfo] = useState<any>(null);

    const handleTokenCloneChange = (e) => {
        setTokenClone(e.target.value);
    };

    const checkDex = () => {
        if(tokenClone !== ''){
            fetch('https://api.dexscreener.com/latest/dex/tokens/' + tokenClone)
                .then(response => {
                    return response.json(); 
                })
                .then(response => {
                    setLogParam(response);
                    

                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
        else{
            alert("enter token!");
        }

    }

    const checkToken = () => {
        if(tokenClone !== ''){
            fetch('https://tool.tokencheck.io/token/info/' + tokenClone)
                .then(response => {
                    return response.json(); 
                })
                .then(response => {
                    setLogParam(response);
                    setTokenInfo(response.data);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
        else{
            alert("enter token!");
        }
    }



    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [createTokenButtonName, setCreateTokenButtonName] = useState("CREATE");

    const handleCreateToken = () => {

        setButtonDisabled(true);
        setCreateTokenButtonName("CREATING...");

        // alert(tokenInfo.supply);
        fetch('https://private3.neutron.ag/solana/create_token',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(tokenInfo)
            }
        )
        .then(response => {
            return response.json(); 
        })
        .then(response => {
             const data = response.data;
             setLogParam(data);
             setTimeTaken(response.time);

             setCreateTokenButtonName("CREATE");
        })
        .catch(error => {
            console.log('Fetch Error:', error);
        });
    }

    const handleInfoChange = (event) => {
        const { name, value } = event.target;
        setTokenInfo({
        ...tokenInfo,
        [name]: value
        });
    }


    return (
        <div className='md:flex gap-4'>
            <div className="w-1/2 max-sm:w-full">
                <div className='w-full x-board p-4'>
                    <WalletBoard/>
                </div>
                <div className='w-full x-board mt-4 p-4'>
                    <table className="w-full">
                        <tbody>
                        
                            <tr><td>Clone</td><td>
                                <div className="flex">
                                    <input className="w-full p-1.5 text-black" type="text" 
                                            onChange={handleTokenCloneChange} 
                                            defaultValue={tokenClone}/>
                                    <button className="border px-2 text-orange-500 whitespace-nowrap" onClick={checkDex}>Dex</button>
                                    <button className="border px-2 text-orange-500 whitespace-nowrap" onClick={checkToken} >TokenCheck</button>
                                </div>
                            </td></tr>
                            <tr><td>Name</td><td><input name="name" className="w-full p-1.5 text-black" defaultValue={tokenInfo?.name} onChange={handleInfoChange} type="text"/></td></tr>
                            <tr><td>Symbol</td><td><input name="symbol" className="w-full p-1.5 text-black" defaultValue={tokenInfo?.symbol} onChange={handleInfoChange}  type="text"/></td></tr>
                            <tr><td>Image URL</td><td><input name="image" className="w-full p-1.5 text-black" defaultValue={tokenInfo?.image} onChange={handleInfoChange} type="text"/></td></tr>
                            <tr><td>Description</td><td><input name="description" className="w-full p-1.5 text-black" defaultValue={tokenInfo?.description} onChange={handleInfoChange} type="text"/></td></tr>
                            <tr><td>Twitter</td><td><input name="twitter" className="w-full p-1.5 text-black" defaultValue={tokenInfo?.twitter} onChange={handleInfoChange} type="text"/></td></tr>
                            <tr><td>Telegram</td><td><input name="telegram" className="w-full p-1.5 text-black" defaultValue={tokenInfo?.telegram} onChange={handleInfoChange} type="text"/></td></tr>
                            <tr><td>Website</td><td><input name="website" className="w-full p-1.5 text-black" defaultValue={tokenInfo?.website} onChange={handleInfoChange} type="text"/></td></tr>
                            <tr><td>Supply</td><td><input name="supply" className="w-full p-1.5 text-black" defaultValue={tokenInfo?.supply} onChange={handleInfoChange} type="text"/>
                                    <div className='text-yellow-300'>{formatNumber(tokenInfo?.supply)}</div>
                                </td>
                            </tr>
                            <tr><td>Decimals</td><td><input name="decimals" className="w-full p-1.5 text-black" defaultValue={tokenInfo?.decimals} onChange={handleInfoChange}  type="text"/></td></tr>
                            <tr><td>Creator</td><td><input name="creator" className="w-full p-1.5 text-black" onChange={handleInfoChange}  type="text"/></td></tr>
                            <tr><td></td><td><button className='button text-sm text-black  bg-yellow-300  p-2 pl-4 pr-4' onClick={handleCreateToken} disabled={isButtonDisabled}>{createTokenButtonName}</button></td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="w-1/2 max-sm:w-full  max-sm:mt-4">
                 <Log logParam ={logParam} logTitle = {logTitle} timeTaken = {timeTaken}/>
            </div>
        </div>
            
    );

}

export default CreateTokenArea