import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const HistoryMenu = () => {
    const [activeItem, setActiveItem] = useState('history');

    const location = useLocation(); // get current URL

    useEffect(() => {
        // update state based on URL
        if (location.pathname === '/history') {
            setActiveItem('history-token');
        } 
        else if (location.pathname === '/history/token') {
            setActiveItem('history-token');
        } 
        else if (location.pathname === '/history/mint') {
            setActiveItem('history-mint');
        } 

      }, [location]); // update when location changes


    return (
        <div className="x-board p-2 gap-4 flex justify-center items-center">
            <div className="flex">
                <Link to="/history/token" className={activeItem === 'history-token' ? 'text-highlight' : ''}>Token</Link>
            </div>
            <div className="flex">
                <Link to="/history/mint" className={activeItem === 'history-mint' ? 'text-highlight' : ''}>Mint</Link>
            </div>
            <div className="flex">
                <Link to="/history/burn" className={activeItem === 'history-burn' ? 'text-highlight' : ''}>Burn</Link>
            </div>
            <div className="flex">
                <Link to="/history/swap" className={activeItem === 'history-swap' ? 'text-highlight' : ''}>Swap</Link>
            </div>
        </div>  
    )

}

export default HistoryMenu;
