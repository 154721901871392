import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import MarketID from './MarketID';
import {shortTokenLink, solBeautify, solNumber} from '../../components/Helper';


const RemoveLiquid : React.FC = () => {
    const [param, setParam] = useState<any>(null);
    const [logParam, setLogParam] = useState<any>(null);
    const [logTitle, setLogTitle] = useState<any>(null);
    const [timeTaken, setTimeTaken] = useState(0);

    const [Remove_LP_Percent, setRemove_LP_Percent] = useState(1); //100%

    const [marketId, setMarketId] = useState('');

    useEffect(() => {
        fetch('https://private.neutron.ag/params')
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                setParam(data);

                setMarketId(data?.Market_ID);
                setRemove_LP_Percent(data?.Remove_LP_Percent);

            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    },[]);

    const [walletList, setWalletList] = useState([]);

    useEffect(() => {
        const config_names = { "wallets" : ["LP_wallet"] };
        get_config(config_names);
    }, []);

    function get_config(params){
        fetch('https://private.neutron.ag/config/',
                {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(params)
                }
              )
              .then(response => {
                  return response.json(); 
              })
              .then(data => {
                setWalletList(data);
              })
              .catch(error => {
                  console.log('Fetch Error:', error);
              });
    }

    const [balanceLP, setBalanceLP] = useState<any>(null);

    useEffect(() => {
        if(walletList != null) {
            fetch_balance(walletList?walletList["LP_wallet"]:null, "LP");
        }
    },[walletList]);

    function fetch_balance(pubKey, type){
        if(pubKey != null){
            fetch('https://private.neutron.ag/balance/' + pubKey)
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    if(type === "LP"){
                        setBalanceLP(data);
                    }
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    }


    return (
        <><Header/>
          <div className="mx-auto">
            <div className="w-ful md:flex mt-4 gap-4">
                <div className="w-1/6 max-sm:w-full max-sm:flex justify-center">
                    <ToolNavigation/>
                </div>

                <div className="w-5/6 max-sm:w-full max-sm:mt-8 md:flex gap-4">
                    <div className='w-2/3 max-sm:w-full'>
                        <MarketID marketID={marketId}/>

                        <div className='w-full  mt-4 x-board p-4'>
                            <table className='table-auto w-full'>
                                    <tr className="align-top">
                                        <td>LP Wallet</td>
                                        <td>
                                            <div>{walletList?walletList["LP_wallet"]:null} <a href='/tool' className='underline text-orange-500 float-right'>Change</a></div>
                                            <div>Max : {solNumber(balanceLP?.balance)} SOL</div>
                                        </td>
                                    </tr>

                                    <tr className="align-top">
                                        <td>Remove LP %</td>
                                        <td><input className="w-full text-black px-2 py-1" type="text" defaultValue={Remove_LP_Percent}/>                                
                                        </td>
                                    </tr>
                                    <tr className="align-top">
                                        <td></td>
                                        <td>
                                            <div className='mt-4 flex'>
                                                <button className="border px-2 text-orange-500">Remove Liquid</button>
                                            </div>
                                        </td>
                                    </tr>
                            </table>
                        </div>
                    </div>

                    <div className="w-1/3 max-sm:w-full max-sm:mt-4 x-board p-4">
                        <div className='text-orange-500'>Task : {logTitle}</div>
                        <div className='text-orange-500'>Time : {Math.floor(timeTaken)} (ms)</div>
                        <div className='whitespace-normal overflow-x-auto'>
                        <pre>{logParam?JSON.stringify(logParam,undefined, 2) :""}</pre>  
                        </div>
                    </div>

                </div>

            </div>
          </div>
      
      </>
    )

}

export default RemoveLiquid