import React  from 'react';
import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 


const ToolSwap : React.FC = () => {

    return (
      <>
        <Header/>
        <div className="mx-auto">
            <div className="w-ful md:flex mt-4 gap-4">
                <div className="w-1/6 max-sm:w-full max-sm:flex justify-center">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                </div>
            </div>
        </div>
      
      </>
    )
  }
  
export default ToolSwap