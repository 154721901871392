import React, { useEffect, useState, useRef } from 'react';

const TokenAmount = ({ publicKey, tokenMint }) => {
  const divRef = useRef(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [tokenAmount, setTokenAmount] = useState(0);

  useEffect(() => {
    if (divRef.current &&  !hasLoaded) {
      loadTokenAmount(divRef.current, publicKey, tokenMint);
      setHasLoaded(true);
    }
  }, [publicKey, hasLoaded]); // Đảm bảo useEffect chạy lại khi item thay đổi

  const loadTokenAmount = (element, publicKey, tokenMint) => {
        // console.log('Div element:', element);
        // console.log('Public key:', publicKey);
        if(publicKey !== '' && tokenMint !== ''){
            const params = {"wallet" : publicKey, "mint" : tokenMint};

            fetch('https://private.neutron.ag/token_amount/',
                    {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(params)
                    }
                )
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                setTokenAmount(data);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
          }
  };

  return (
    <div ref={divRef}>{tokenAmount}</div>
  );
};

export default TokenAmount;