import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react';
import { useState , useEffect } from 'react'
import Modal from './Modal';
import PaymentButton from './PaymentButton';
import { useSticky } from './StickyContext';

require('@solana/wallet-adapter-react-ui/styles.css')

//kết nối và thanh toán
const ConnectButton = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {setIsModalOpen(true);  }
    const closeModal = () => {setIsModalOpen(false);  }

    const { connect, disconnect, publicKey, connected } = useWallet();

    // const handleConnect = () => {
    //     connect();
    // };

    // const handleDisconnect = () => {
    //     disconnect();
    // };


    //check user exist 
    useEffect(() => {
        if(publicKey != null){ 
            fetch('https://api.tokencheck.io/user/' + publicKey.toBase58())
                .then(response => {
                    // Xử lý khi nhận được response từ server
                    return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
                })
                .then(data => {
                    // Xử lý dữ liệu đã nhận được từ server              
                    
                })
                .catch(error => {
                  // Xử lý khi gặp lỗi trong quá trình fetch
                    console.log('Fetch Error:', error);
                });
          }
    }, [publicKey]);



    return (
        <>
             {connected ? (
                <div className="flex">
                    <button className="border border-gray-600 rounded px-1 ml-2 flex" onClick={openModal}>
                            <span>{publicKey?"@" + publicKey.toBase58().substring(0,3):""}</span> 
                            <span className="ml-1 -mt-1"><i class="fa-solid fa-sort-down text-xs"></i></span></button>

                    <Modal isOpen={isModalOpen} onClose={closeModal} />
                </div>
                
            ) : (
                <>
                [<WalletMultiButton 
                    // style={{
                    //     background: 'linear-gradient(45deg, #9945ff, #14F195)',
                    //     color: '#fff',
                    //     border: 'none',
                    //     cursor: 'pointer',
                    // }}
                    
                    style={{
                        background:'none',
                        border: 'none',
                        color: '#fff', 
                        cursor: 'pointer',
                        padding: 0,
                        fontWeight : '400',
                        // fontSize : '0.875rem',
                        height : '1.6rem',
                    }}

                />]
                </>
            )}
        </>
    )
}

export  default ConnectButton;
