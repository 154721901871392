import React, { useState, useEffect } from 'react';
import {extensionLink } from '../../components/Helper'; //getMeta,
import ToolNavigation from './ToolNavigation'; 

const SendSolArea = () => {

  const [fetchTime, setFetchTime] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [balance, setBalance] = useState(0);

  const [toAddress, setToAddress] = useState('');
  const [toAddressValid, setToAddressValid] = useState(false);
  const [amount, setAmount] = useState(0);

  const [log, setLog] = useState('');

//   const handlePrivateKeyPress = (e) => {
//     if (e.key === 'Enter') {
//         privateKeyEnterPress();
//     }
//   };
  const handleAmount = (e) => {
    const amount = e.target.value;
    setAmount(amount);
  }

  const handlePublicChange = (e) => {
    const toAddress = e.target.value;
    console.log('Đã nhấn Enter! Giá trị:', toAddress);
    setToAddress(toAddress);

    if(toAddress != null && toAddress !== ""){
        fetch('https://tool.tokencheck.io/address/valid/' + toAddress)
            .then(response => {
            // Xử lý khi nhận được response từ server
            return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
            })
            .then(data => {
            
                if(data.hasOwnProperty("statusCode") ) {
                    
                }
                else{
                    setToAddressValid(data.valid);
                    setFetchTime(Math.floor(data.fetchTime) + "ms");
                }
            })    
            .catch(error => {
                // Xử lý khi gặp lỗi trong quá trình fetch
                console.log('Fetch Error:', error);
        });
    }
  }

  const handlePrivateKeyChange = (e) => {
    // Xử lý logic khi người dùng nhấn Enter ở đây
    const privateKey = e.target.value;
    console.log('Đã nhấn Enter! Giá trị:', privateKey);

    setPrivateKey(privateKey);

    if(privateKey != null && privateKey !== ""){
        fetch('https://tool.tokencheck.io/address/balance/' + privateKey)
                .then(response => {
                // Xử lý khi nhận được response từ server
                return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
                })
                .then(data => {
                // Xử lý dữ liệu đã nhận được từ server
                // console.log(data);
                // setData(data);
                    if(data.hasOwnProperty("statusCode") ) {
                        setPublicKey("");
                        setBalance(0);
                    }
                    else{
                        setPublicKey(data.publicKey);
                        setBalance(data.balance  / 10 ** 9);
                        setFetchTime(Math.floor(data.fetchTime) + "ms");
                    }
                })    
            .catch(error => {
                    // Xử lý khi gặp lỗi trong quá trình fetch
                    console.log('Fetch Error:', error);
        });
    }

  };


  const handleSendClick = () => {
    // const toAddress; 
    //send
    if(toAddressValid && publicKey != null && amount !== 0){
        const lamports = amount * 10**9;
        fetch('https://tool.tokencheck.io/send/sol/' + privateKey + "/" + toAddress + "/" + lamports)
                .then(response => {
                // Xử lý khi nhận được response từ server
                return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
                })
                .then(data => {
                // Xử lý dữ liệu đã nhận được từ server
                // console.log(data);
                // setData(data);
                    if(data.hasOwnProperty("statusCode") ) {
                      
                    }
                    else{
                        setFetchTime(Math.floor(data.fetchTime) + "ms");
                        setLog(data.signature);
                    }
                })    
            .catch(error => {
                    // Xử lý khi gặp lỗi trong quá trình fetch
                    console.log('Fetch Error:', error);
        });
    }
  };

  
    return (
            <div className='md:flex gap-4'>
                <div className="w-1/2 max-sm:w-full x-board p-4">
                    <table className="table-auto text-md w-full">
                        <tbody>
                            <tr><td className="flex">Private Key</td>
                                <td><input 
                                defaultValue=""
                                onChange={handlePrivateKeyChange}
                                className="w-full p-1.5 text-black" type="text"/></td>
                            </tr>
                            <tr>
                                <td className="flex">Public Key</td>
                                <td><input 
                                    defaultValue={publicKey}
                                    disabled className="w-full p-1.5"   type="text"/></td>
                            </tr>
                            <tr>
                                <td className="flex">Balance</td>
                                <td><input 
                                    value={balance}
                                    disabled className="w-full p-1.5 text-black"  type="text"/></td>
                            </tr>
                            <tr>
                            <td className="flex"><div className="flex gap-4"><div>To Address </div><div className="text-highlight">{toAddressValid?"--Valid" : "--No"}</div></div></td>
                                <td><input 
                                    value={toAddress}
                                    onChange={handlePublicChange}
                                    className="w-full p-1.5 text-black"  type="text"/></td>
                            </tr>
                            <tr>
                                <td className="flex">Balance</td>
                                <td><input 
                                    value={amount}
                                    onChange={handleAmount}
                                    className="w-full p-1.5 text-black"  type="text"/></td>
                            </tr>
                            <tr><td></td>
                                <td>
                                    <button 
                                    onClick={handleSendClick}
                                    className="button  text-black bg-yellow-300  p-2 mt-2">SEND</button>
                                </td>
                            </tr>
                        </tbody>             
                    </table>
                </div>
                <div className='w-1/2 max-sm:w-full  max-sm:mt-4 x-board p-4'>
                    <div className='flex'>P-Time : <div>{fetchTime}</div></div>
                    <div>{log}</div>
                </div>
            </div>

    );

}

export default SendSolArea