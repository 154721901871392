import React, { useContext } from 'react';
import  {WalletContextProvider} from './WalletContextProvider';
import ConnectBox from './ConnectBox';
import WalletDisplay from './WalletDisplay';

const Modal = ({ isOpen, onClose }) => {
  
  if (!isOpen) return null;

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  

  return (
    <div  onClick={handleBackgroundClick}
        className="modal fixed inset-0 bg-black/80 flex items-center justify-center">
      <div className="p-6 text-white rounded-lg overflow-hidden shadow-xl transform transition-all 
                      sm:max-w-lg sm:w-full bg-primary z-20 border border-slate-200 text-center">
        
        <div className="grid gap-4 justify-center">
        
            <WalletContextProvider>
                  <WalletDisplay/>
                  <ConnectBox/>    
            </WalletContextProvider>
            
            <div class="text-slate-50 hover:font-bold hover:text-slate-50 cursor-pointer w-fit justify-self-center" 
                 onClick={handleBackgroundClick}>[close]</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
