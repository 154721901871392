import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 


const Param : React.FC = () => {

    const params = ["RPC_URL", "PINATA"]; 
    const [param, setParam] = useState<any>(null);
   
   
    useEffect(() => {
        fetch('https://private.neutron.ag/params',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params)
                }
            )
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                setParam(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    },[]);


    const [inputValues, setInputValues] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues({
          ...inputValues,
          [name]: value
        });
      };
      

    const handleUpdateParam = () => {
        const params = inputValues;
        fetch('https://private.neutron.ag/update_params/',
                {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(params)
                }
              )
              .then(response => {
                  return response.json(); 
              })
              .then(data => {
                    alert(data.success);
              })
              .catch(error => {
                  console.log('Fetch Error:', error);
              });
    }

    return (
      <><Header/>
        <div className="mx-auto">
            <div className="w-ful md:flex mt-4 gap-4">
                <div className="w-1/6 max-sm:w-full max-sm:flex justify-center">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                    <div className='w-full md:flex gap-4'>
                        <div className='w-2/3 max-sm:w-full'>
                            <div className='w-full x-board p-4'>
                                <table className='table-auto w-full'>

                                    {
                                        params.map((item, index) => {

                                            return (<tr className="align-top">
                                                        <td>{item}</td> 
                                                        <td><input className="w-full text-black px-2 py-1" name={item} onChange={handleInputChange} type="text" defaultValue={param?param[item]:""}/></td>
                                                    </tr>)
                                        })
                                    }

                                    <tr className="align-top">
                                        <td></td>
                                        <td>
                                            <button className='button border p-2 mt-4' onClick={handleUpdateParam}>UPDATE</button>
                                        </td>
                                    </tr>


                                    
                                </table>
                            </div>
                        </div>
                        <div className='w-1/3 max-sm:w-full max-sm:mt-4 p-4'>
                            <div className='text-yellow-300'>PINATA</div>
                             <div className='break-all'>{param?.PINATA}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      
      </>
    )
  }
  
export default Param