import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const ToolNavigation = () => {
    const [activeItem, setActiveItem] = useState('tool'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        // update state based on URL
        if (location.pathname === '/tool-token/') {
          setActiveItem('tool');
        } 
        else if (location.pathname === '/tool-token/send-sol') {
          setActiveItem('send-sol');
        } 
        else if (location.pathname === '/tool-token/send-token') {
          setActiveItem('send-token');
        }
        else if (location.pathname === '/tool-token/create-token') {
          setActiveItem('create-token');
        }
        else if (location.pathname === '/tool-token/update-token') {
          setActiveItem('update-token');
        }
        else if (location.pathname === '/tool-token/send-wallet') {
          setActiveItem('send-wallet');
        }
        else if (location.pathname === '/tool-token/param') {
          setActiveItem('param');
        }
        else if (location.pathname === '/tool-token/mint-token') {
          setActiveItem('mint-token');
        }

      }, [location]); // update when location changes

    return (
        //navigation
        <div className="x-board p-4 grid gap-4 max-sm:flex">
            <div className="flex">
                <Link to="/tool-token/create-token" className={activeItem === 'create-token' ? 'text-highlight' : ''}>Create Token</Link>
            </div>

            <div className="flex">
                <Link to="/tool-token/mint-token" className={activeItem === 'mint-token' ? 'text-highlight' : ''}>Mint Token</Link>
            </div>

            <div className="flex">
                <Link to="/tool-token/burn-spl" className={activeItem === 'burn-spl' ? 'text-highlight' : ''}>Burn SPL</Link>
            </div>

            <div className="flex">
                <Link to="/tool-token/send-sol" className={activeItem === 'send-sol' ? 'text-highlight' : ''}>Send SOL</Link>
            </div>

            <div className="flex">
                <Link to="/tool-token/send-wallet" className={activeItem === 'send-wallet' ? 'text-highlight' : ''}>Send Wallet</Link>
            </div>

            <div className="flex">
                <Link to="/tool-token/update-token" className={activeItem === 'update-token' ? 'text-highlight' : ''}>Update Meta</Link>
            </div>

            <div className="flex">
                <Link to="/tool-token/param" className={activeItem === 'param' ? 'text-highlight' : ''}>Param</Link>
            </div>
        </div>
    );

}

export default ToolNavigation;