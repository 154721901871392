import React, { useState, useEffect } from 'react';
import SendSolArea from './SendSolArea';
import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 

function SendSol() {
    return (
      <><Header/>
        <div className="mx-auto">
            <div className="w-ful md:flex mt-4 gap-4">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                    <SendSolArea/>
                </div>
            </div>
        </div>
      
      </>
    )
  }
  
export default SendSol