import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import {shortTokenLink, solBeautify, solNumber} from '../../components/Helper';


const Param : React.FC = () => {

    const params = ["Engine_URL", "RPC_URL", "Open_Time", "Sell_Remove_Fee", "Add_LP_SOL", "Add_LP_Percent", "BuyFirst_Amount", "Remove_LP_Percent", "Buy_Amount" , "Sell_Percent", "Market_ID"];

    const [param, setParam] = useState<any>(null);
    const [fee, setFee] = useState<any>(null);
   
    useEffect(() => {
        fetch('https://private.neutron.ag/params', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            })
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                setParam(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    },[]);

    useEffect(() => {
        fetch('https://private.neutron.ag/budget_config_high')
        .then(response => {
            return response.json(); 
        })
        .then(data => {
            setFee(data);
        })
        .catch(error => {
            console.log('Fetch Error:', error);
        });
    },[]);

    const [inputValues, setInputValues] = useState({
        // Engine_URL: ''
       
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues({
          ...inputValues,
          [name]: value
        });
      };
      

    const handleUpdateParam = () => {
        const params = inputValues;
        fetch('https://private.neutron.ag/update_params/',
                {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(params)
                }
              )
              .then(response => {
                  return response.json(); 
              })
              .then(data => {
                    alert(data.success);
              })
              .catch(error => {
                  console.log('Fetch Error:', error);
              });
    }

    return (
      <><Header/>
        <div className="mx-auto">
            <div className="w-ful md:flex mt-4 gap-4">
                <div className="w-1/6 max-sm:w-full max-sm:flex justify-center">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                    <div className='w-full md:flex gap-4'>
                        <div className='w-2/3 max-sm:w-full'>
                            <div className='w-full x-board p-4'>
                                <table className='table-auto w-full'>

                                    {
                                        params.map((item, index) => {

                                            return (<tr className="align-top">
                                                        <td>{item}</td> 
                                                        <td><input className="w-full text-black px-2 py-1" name={item} onChange={handleInputChange} type="text" defaultValue={param?param[item]:""}/></td>
                                                    </tr>)
                                        })
                                    }

                                    <tr className="align-top">
                                        <td></td>
                                        <td>
                                            <button className='button border p-2 mt-4' onClick={handleUpdateParam}>UPDATE</button>
                                        </td>
                                    </tr>


                                    
                                </table>
                            </div>
                        </div>
                        <div className='w-1/3 max-sm:w-full max-sm:mt-4 '>
                            <div className="w-full x-board p-4 text-xs">
                                <div>https://solanacompass.com/api/fees</div>
                                <div className='flex mt-4'>Compute Fee : <div className='text-orange-500 ml-2'>{fee?JSON.stringify(fee) :""}</div></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      
      </>
    )
  }
  
export default Param